<template>
  <div style="padding: 16px; padding-top: 0">

    <!-- 头部部分 -->
    <div ref="search_d">
      <el-form :inline="true" label-width="80px">
        <el-form-item label="发布人:">
          <el-input
            style="width: 140px"
            v-model="queryInfo.condition.memberName"
            clearable
            placeholder="发布人"
          ></el-input>
        </el-form-item>

        <el-form-item label="标题:">
          <el-input
            style="width: 140px"
            v-model="queryInfo.condition.title"
            clearable
            placeholder="标题"
          ></el-input>
        </el-form-item>

        <el-form-item label="所在城市:">
          <el-input
            style="width: 140px"
            v-model="queryInfo.condition.cityName"
            clearable
            placeholder="所在城市"
          ></el-input>
        </el-form-item>

        <el-button type="primary" :loading="tableLoading" @click="getList"
          >搜索</el-button
        >
      </el-form>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table
        border
        height="calc(100vh - 220px)"
        v-loading="tableLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)"
        ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          label="序号"
          fixed="left"
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          label="发布用户名称"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.memberName }}</template>
        </el-table-column>
        <el-table-column label="用户头像" align="center" min-width="65px">
          <template slot-scope="{ row }">
            <el-image
              :preview-src-list="[row.memberLogo]"
              style="width: 60px; height: 60px"
              :src="row.memberLogo"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="标题" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.title }}</template>
        </el-table-column>
        <el-table-column label="视频" align="center">
          <template slot-scope="{ row }">
            <el-button @click="showVideo(row.videoUrl)" type="text"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="喜欢数量" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.likeNum }}</template>
        </el-table-column>
        <el-table-column label="评论数量" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.commentNumber }}</template>
        </el-table-column>
        <el-table-column label="转发数量" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.forwardNum }}</template>
        </el-table-column>
        <el-table-column label="浏览量" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.viewNum }}</template>
        </el-table-column>
        <el-table-column label="所在城市" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.cityName }}</template>
        </el-table-column>
        <el-table-column label="封面图" align="center" min-width="65px">
          <template slot-scope="{ row }">
            <el-image
              v-if="row.videoImg"
              :preview-src-list="[row.videoImg]"
              style="width: 60px; height: 60px"
              :src="row.videoImg"
            ></el-image>
          </template>
        </el-table-column>
        <!-- <el-table-column label="比例" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.rate }}</template>
          </el-table-column> -->
        <el-table-column label="审核状态" align="center" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <el-tag v-if="row.status == 0" type="info">审核中</el-tag>
            <el-tag v-if="row.status == 1" type="success">已通过</el-tag>
            <el-tag v-if="row.status == 2" type="danger">已拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="审核反馈" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.auditFeedBack }}</template>
        </el-table-column>
        <!-- <el-table-column label="排序" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.seq }}</template>
          </el-table-column> -->
        <el-table-column label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createDt }}</template>
        </el-table-column>
        <el-table-column label="标签" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.label }}</template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150" align="center">
          <template slot-scope="scope">
            <div class="buttonPadding">
              <el-button
                v-if="scope.row.status == 0"
                type="text"
                @click="edit(scope.row)"
                size="small"
                >审核</el-button
              >
              <el-button
                style="color: #f59a23"
                type="text"
                @click="addLabel(scope.row)"
                size="small"
                >标签</el-button
              >
              <el-popconfirm
                title="确定删除吗？"
                @confirm="handleDelte(scope.row.id)"
              >
                <el-button slot="reference" style="color: red" type="text"
                  >删除</el-button
                >
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :title="labelType == 'add' ? '新增' : '审核'"
      :visible.sync="showDialog"
      width="800px"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="90px">
        <el-row :gutter="10">
          <el-col :span="12">
            <video
              :poster="editObj.videoImg"
              style="width: 80%; height: 60vh"
              controls
              autoplay
              muted
              :src="editObj.videoUrl"
            ></video>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="24">
                <el-form-item label="审核意见">
                  <el-select style="width: 208px" v-model="editObj.status">
                    <!-- <el-option label="待审核" value="0"></el-option> -->
                    <el-option label="通过" value="1"></el-option>
                    <el-option label="不通过" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="editObj.status == 2">
                <el-form-item label="审核反馈">
                  <el-input
                    placeholder="请输入整改意见"
                    :autosize="{ minRows: 3, maxRows: 12 }"
                    type="textarea"
                    style="width: 208px"
                    v-model.trim="editObj.auditFeedBack"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="视频标签">
                  <div>
                    <el-input
                      @keyup.enter.native="addTagListEdit"
                      style="width: 208px"
                      v-model.trim="editObj.label"
                    ></el-input>
                    <el-button @click="addTagListEdit" type="text"
                      >添加</el-button
                    >
                  </div>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="">
                  <div style="width: 208px">
                    <el-tag
                      @close="delTag(tag)"
                      style="margin-right: 6px"
                      v-for="tag in tagList"
                      :key="tag"
                      closable
                      type="success"
                    >
                      {{ tag }}
                    </el-tag>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="videoDialog"
      :before-close="handleClose"
      width="50%"
    >
      <div style="height: 60vh; width: 100%">
        <video
          style="width: 100%; height: 100%"
          controls
          :src="videoUrl"
          ref="videoPlayer"
        ></video>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="videoDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="videoDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 添加标签 -->
    <el-dialog
      :title="'添加标签___' + labelObj.title"
      :visible.sync="labelDialog"
      width="620px"
    >
      <el-form label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="视频标签">
              <div>
                <el-input
                  @keyup.enter.native="addTagList"
                  style="width: 220px"
                  v-model="labelObj.label"
                ></el-input>
                <el-button @click="addTagList" type="text">添加</el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <el-tag
                @close="delTag(tag)"
                style="margin-right: 6px"
                v-for="tag in tagList"
                :key="tag"
                closable
                type="success"
              >
                {{ tag }}
              </el-tag>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="labelDialog = false">取 消</el-button>
        <el-button type="primary" @click="labelBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagList: [],
      tableLoading: false,
      labelObj: {
        id: "",
        title: "",
        label: "",
      },
      labelDialog: false,
      editObj: {
        videoUrl: "",
        status: "",
        videoImg: "",
        label: "",
        id: "",
        auditFeedBack: "",
      },
      videoDialog: false,
      videoUrl: "",
      labelType: "add",
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 20,
        pageCount: 1,
        currPage: 1,
        condition: {
          memberName: "",
          title: "",
          cityName: "",
        },
      },
      form: {},
      showDialog: false,
      tableData: [],
    };
  },

  created() {
    this.getList();
  },

  mounted() {},

  methods: {
    addTagList() {
      if (this.labelObj.label.trim()) {
        let isRepeat = this.tagList.includes(this.labelObj.label.trim());
        if (isRepeat) {
          this.$message.info("当前标签已存在，请勿重复添加");
        } else {
          this.tagList.push(this.labelObj.label.trim());
          this.labelObj.label = "";
        }
      }
    },
    addTagListEdit() {
      if (this.editObj.label.trim()) {
        let isRepeat = this.tagList.includes(this.editObj.label.trim());
        if (isRepeat) {
          this.$message.info("当前标签已存在，请勿重复添加");
        } else {
          this.tagList.push(this.editObj.label.trim());
          this.editObj.label = "";
        }
      }
    },
    handleClose(done) {
      // 暂停视频播放\
      console.log(this.$refs.videoPlayer,390);
      this.$refs.videoPlayer.pause();
      // 关闭弹框
      done(); // 调用 done() 表示关闭弹框
    },
    delTag(tag) {
      let index = this.tagList.indexOf(tag);
      this.tagList.splice(index, 1);
    },
    async labelBtn() {
      let data = JSON.parse(JSON.stringify(this.labelObj));
      data.label = this.tagList.join(",");
      let res = await this.$http.post("video/addLabel", data);
      if (res.data.code == 200) {
        this.$notify.success({
          title: "提示",
          message: "保存成功",
          showClose: true,
        });
        this.labelDialog = false;
        this.getList();
        this.labelObj = {
          id: "",
          title: "",
          label: "",
        };
      } else {
        this.$message.error(res.data.message);
      }
    },
    addLabel(row) {
      let { id, title, label } = row;
      this.labelObj.id = id;
      this.labelObj.title = title;
      this.labelObj.label = "";
      if (row.label) {
        this.tagList = label.split(",");
      } else {
        this.tagList = [];
      }

      this.labelDialog = true;
    },
    showVideo(url) {
      this.videoUrl = url;
      this.videoDialog = true;
    },

    //获取数据
    getList() {
      this.tableLoading = true;
      var that = this;
      that.$http.post("/video/list", that.queryInfo).then(function (response) {
        that.tableLoading = false;
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage;
          that.tableData = response.data.data.data;
        }
      });
    },

    // 保存
    async handleSave() {
      if (this.editObj.status == 2 && this.editObj.auditFeedBack == "") {
        this.$message.info("请输入反馈意见");
        return;
      }
      let data = JSON.parse(JSON.stringify(this.editObj));
      data.label = this.tagList.join(",");
      let res = await this.$http.post("video/check", data);
      if (res.data.code == 200) {
        this.$notify.success({
          title: "提示",
          message: "保存成功",
          showClose: true,
        });
        this.showDialog = false;
        this.getList();
      } else {
        this.$message.error(res.data.message);
      }
    },

    //修改
    edit(row) {
      console.log(row, "row");
      this.showDialog = true;
      this.labelType = "edit";
      // this.form = JSON.parse(JSON.stringify(row))
      let { status, videoUrl, videoImg, label, id, auditFeedBack } = row;
      if (status == 0) {
        this.editObj.status = "1";
      } else {
        this.editObj.status = status.toString();
      }

      this.editObj.videoUrl = videoUrl;
      this.editObj.videoImg = videoImg;
      this.editObj.label = "";
      this.tagList = label.split(",");
      this.editObj.id = id;
      this.editObj.auditFeedBack = auditFeedBack;
    },

    // 删除当前行
    //    handleDelte (id) {
    //      del({ id: id }).then(res => {
    //        if (res.code == 200) {
    //         this.$message.success('删除成功')
    //         this.getList()
    //       }
    //      })
    //    },

    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http.post("/video/delete", { id: id }).then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
      }
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {};
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.buttonPadding {
  .el-button {
    padding: 0 4px;
    margin-left: 0;
  }
}
/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #409eff;
  cursor: pointer;
}
/deep/.el-table__fixed {
  height: calc(100% - 8px) !important;
}
/deep/.el-table__fixed-right {
  height: calc(100% - 8px) !important;
}
</style>
